
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import Billing from "@/views/sbmx/account/Billing.vue";
import {
  AccountFeatures,
  Actions,
  ContactChoices,
  PlanCategories,
} from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Plan, Price, PriceTier } from "@/store/modules/AuthModule";

interface SearchResult {
  plan: Plan;
  price: Price;
  priceTier: PriceTier;
}

interface SearchLineItem {
  searchResult: SearchResult;
  quantity: number;
  price: number;
}

interface NewPrice {
  priceTierId: number;
  quantity: number;
}

interface SubscriptionSummary {
  lineItems: SearchLineItem[];
  total: number;
  chargeFrequency: string;
}

export default defineComponent({
  name: "account-billing",
  components: { Billing },
  setup() {
    const store = useStore();
    const priceFilter = ref<string>("monthly");
    const newNumberOfLocations = ref<number>(0);
    const newNumberOfContacts = ref<number>(0);
    const loading = ref<boolean>(false);
    const newPlan = ref<number>(0);
    const newChargeFrequency = ref<string>("month");
    const newIncludeSportsTVGuide = ref<boolean>(true);

    const selectPlan = async (price) => {
      let acctId = store.state.AuthModule.account.id;
      if (acctId !== undefined) {
        let data: NewPrice[] = [];
        let cr = currentResults.value;
        for (let pt = 0; pt < cr.lineItems.length; pt++) {
          data.push({
            priceTierId: cr.lineItems[pt].searchResult.priceTier.id,
            quantity: cr.lineItems[pt].quantity,
          });
        }
        loading.value = true;

        ApiService.post("api/v1/accounts/" + acctId + "/select_plan", {
          newPrices: data,
        })
          .then(() => {
            store.dispatch(Actions.REFRESH_ACCOUNT);
            store.dispatch(Actions.REFRESH_ACCOUNT_SUBSCRIPTIONS);
            Swal.fire({
              text: "Plan has been successfully changed!",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          })
          .catch(() => {
            store.dispatch(Actions.REFRESH_ACCOUNT);
            store.dispatch(Actions.REFRESH_ACCOUNT_SUBSCRIPTIONS);
            Swal.fire({
              text: "There was an error changing your subscription!",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          })
          .finally(() => {
            loading.value = false;
          });
      }
    };

    const initializeFields = () => {
      filterPriceTiers({
        category: PlanCategories.CONTACT_BASED,
        numberOfContacts: newNumberOfContacts.value,
        chargeInterval: newChargeFrequency.value,
      });

      newPlan.value = findSubscribedPlanByCategory(100);
      newNumberOfContacts.value = store.state.AuthModule.account.contactLimit;
      newNumberOfLocations.value = store.state.AuthModule.account.locationLimit;
      let price = findSubscribedPriceByCategory(100);
      if (price.id !== "") {
        newChargeFrequency.value = price.chargeInterval;
      }
    };

    onMounted(() => {
      store.dispatch(Actions.REFRESH_ACCOUNT_LOCATIONS);
      store.dispatch(Actions.REFRESH_PLANS);
      setCurrentPageBreadcrumbs("Plan", ["Account"]);
      initializeFields();
    });

    const currentResults = computed(() => {
      let planResults = filterPriceTiers({
        category: PlanCategories.CONTACT_BASED,
        numberOfContacts: newNumberOfContacts.value,
        planId: newPlan.value,
        chargeInterval: newChargeFrequency.value,
      });
      let includedFeatures: string[] = [AccountFeatures.LOCATION_ACCESS];
      if (newIncludeSportsTVGuide.value) {
        includedFeatures.push(AccountFeatures.SPORTS_TV_GUIDE);
      }
      let locationResults = filterPriceTiers({
        category: PlanCategories.LOCATION_BASED,
        numberOfLocations: newNumberOfLocations.value,
        chargeInterval: newChargeFrequency.value,
        includedFeatures: includedFeatures,
      });
      planResults = planResults.concat(locationResults);
      let searchLineItems: SearchLineItem[] = [];
      for (let i = 0; i < planResults.length; i++) {
        let total = 0;
        let qty = 1;
        if (planResults[i].plan.planCategory == PlanCategories.CONTACT_BASED) {
          qty = newNumberOfContacts.value;
          total = parseFloat(planResults[i].priceTier.flatAmountDecimal);
        }
        if (planResults[i].plan.planCategory == PlanCategories.LOCATION_BASED) {
          qty = newNumberOfLocations.value;
          total = parseFloat(planResults[i].priceTier.unitAmountDecimal) * qty;
        }
        searchLineItems.push({
          searchResult: planResults[i],
          quantity: qty,
          price: total,
        });
      }

      let summary: SubscriptionSummary = {
        total: 0,
        lineItems: searchLineItems,
        chargeFrequency: newChargeFrequency.value,
      };

      for (let i = 0; i < summary.lineItems.length; i++) {
        summary.total += summary.lineItems[i].price;
      }

      return summary;
    });

    const findSubscribedPlanByCategory = (category) => {
      let subs = store.state.AuthModule.subscriptions;
      if (subs !== undefined) {
        for (let i = 0; i < subs.length; i++) {
          if (subs[i].status === "active") {
            for (let z = 0; z < subs[i].subscriptionItems.length; z++) {
              if (subs[i].subscriptionItems[z].planCategory == 100) {
                return subs[i].subscriptionItems[z].price.plan.id;
              }
            }
          }
        }
      }
      return new Plan();
    };

    const findSubscribedPriceByCategory = (category) => {
      let subs = store.state.AuthModule.subscriptions;
      if (subs !== undefined) {
        for (let i = 0; i < subs.length; i++) {
          if (subs[i].status === "active") {
            for (let z = 0; z < subs[i].subscriptionItems.length; z++) {
              if (subs[i].subscriptionItems[z].planCategory == 100) {
                return subs[i].subscriptionItems[z].price;
              }
            }
          }
        }
      }
      return { id: "" };
    };

    const filterPriceTiers = ({
      category = -1,
      numberOfLocations = -1,
      numberOfContacts = -1,
      planId = -1,
      chargeInterval = "",
      includedFeatures = [],
    }: {
      category?: number;
      numberOfLocations?: number;
      numberOfContacts?: number;
      planId?: number;
      chargeInterval?: string;
      includedFeatures?: string[];
    }) => {
      let results: SearchResult[] = [];
      for (let i = 0; i < store.state.AuthModule.plans.length; i++) {
        if (planId !== -1 && store.state.AuthModule.plans[i].id !== planId) {
          continue;
        }
        if (
          category !== -1 &&
          store.state.AuthModule.plans[i].planCategory !== category
        ) {
          continue;
        }
        if (includedFeatures.length > 0) {
          let found = true;
          if (
            includedFeatures.length !==
            store.state.AuthModule.plans[i].planFeatures.length
          ) {
            continue;
          }
          for (let fi = 0; fi < includedFeatures.length; fi++) {
            if (
              !store.state.AuthModule.plans[i].planFeatures.includes(
                includedFeatures[fi]
              )
            ) {
              found = false;
            }
          }
          if (!found) {
            continue;
          }
        }
        for (
          let c = 0;
          c < store.state.AuthModule.plans[i].prices.length;
          c++
        ) {
          for (
            let pt = 0;
            pt < store.state.AuthModule.plans[i].prices[c].priceTiers.length;
            pt++
          ) {
            if (
              chargeInterval !== "" &&
              store.state.AuthModule.plans[i].prices[c].chargeInterval !==
                chargeInterval
            ) {
              continue;
            }

            if (
              numberOfContacts !== -1 &&
              store.state.AuthModule.plans[i].planCategory ==
                PlanCategories.CONTACT_BASED &&
              store.state.AuthModule.plans[i].prices[c].priceTiers[pt].upTo !==
                numberOfContacts
            ) {
              continue;
            }

            if (
              numberOfLocations !== -1 &&
              store.state.AuthModule.plans[i].planCategory ==
                PlanCategories.LOCATION_BASED &&
              store.state.AuthModule.plans[i].prices[c].priceTiers[pt].upTo !==
                numberOfLocations
            ) {
              continue;
            }

            let item = {
              plan: store.state.AuthModule.plans[i],
              price: store.state.AuthModule.plans[i].prices[c],
              priceTier:
                store.state.AuthModule.plans[i].prices[c].priceTiers[pt],
            };
            results.push(item);
          }
        }
      }

      return results;
    };

    const findMatchingPrice = () => {
      for (let i = 0; i < store.state.AuthModule.plans.length; i++) {
        if (store.state.AuthModule.plans[i].id == newPlan.value) {
          for (
            let c = 0;
            c < store.state.AuthModule.plans[i].prices.length;
            c++
          ) {
            if (
              store.state.AuthModule.plans[i].prices[c].chargeInterval ==
              newChargeFrequency.value
            ) {
              for (
                let pt = 0;
                pt <
                store.state.AuthModule.plans[i].prices[c].priceTiers.length;
                pt++
              )
                if (
                  store.state.AuthModule.plans[i].prices[c].priceTiers[pt]
                    .upTo == newNumberOfContacts.value
                ) {
                  return store.state.AuthModule.plans[i].prices[c].priceTiers[
                    pt
                  ];
                }
            }
          }
        }
      }
      return null;
    };
    watch(
      () => store.state.AuthModule.subscriptions,
      () => {
        initializeFields();
      }
    );

    watch(
      () => store.state.AuthModule.plans,
      () => {
        initializeFields();
      }
    );

    return {
      selectPlan,
      priceFilter,
      newNumberOfLocations,
      newPlan,
      currentResults,
      newNumberOfContacts,
      ContactChoices,
      findMatchingPrice,
      findSubscribedPriceByCategory,
      newChargeFrequency,
      newIncludeSportsTVGuide,
      loading,
    };
  },
});
